<template>
  <div class="page-1-wrapper">
    <div class="w1200 flex align-items-center">
      <div class="wow fadeInUp" data-wow-delay="0.1s">
        <div class="fs30">
          <div style="font-size: 90px">蝉镜AI智能直播</div>
          <div>
            <span style="font-size: 50px">Demo</span>
            <span class="fs26 ml10">(Technical Preview)</span>
          </div>
          <div class="fs20 font-weight-600 mt4">
            懂运营、会问答、能爆单的数字人直播
          </div>
        </div>
        <el-radio-group v-model="demo_id">
          <el-radio label="chanxuan">蝉选选品</el-radio>
          <el-radio label="law-office-1">线索类单人</el-radio>
          <!--          <el-radio label="law-office-2">线索类双人</el-radio>-->
        </el-radio-group>
        <div
          class="flex justify-content-center flex-1"
          style="padding-top: 100px"
        >
          <button @click="goStep">
            <img
              src="https://cdn-static.chanmama.com/sub-module/static-file/0/b/afc610af03"
              width="85"
              alt=""
            />
          </button>
        </div>
      </div>
      <div class="video-wrap wow fadeInRight ml50">
        <div class="blur"></div>
        <div class="video">
          <video
            :src="
              demo_id !== 'chanxuan'
                ? 'https://cdn-static.chanmama.com/res/upload/2024/05/29/chanjing-live-demo-lvsuo.mp4'
                : 'https://cdn-static.chanmama.com/res/upload/2024/12/30/1230record.mp4'
            "
            muted
            autoplay
            loop
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "demo-page-1",
  data() {
    return {
      demo_id: "chanxuan",
    };
  },
  methods: {
    getChannel() {
      let obj = {
        chanxuan: 99,
        "law-office-1": 100,
        "law-office-2": 101,
      };
      return obj[this.demo_id];
    },
    goStep() {
      this.$emit("step", "page2", {
        demo_id: this.demo_id,
        channel: this.getChannel(),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.w1200 {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.page-1-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  font-weight: 600;
  color: #fff;
  .start-btn {
    width: 189px;
    height: 189px;
    border-radius: 50%;
    margin-top: 100px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient(164.22deg, #5398ff 11.02%, #ff69d5 95.21%);
    .blur {
      position: absolute;
      bottom: -10%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      filter: blur(40px);
      opacity: 0.5;
      background: linear-gradient(164.22deg, #5398ff 11.02%, #ff69d5 95.21%);
    }
    img {
      z-index: 10;
    }
  }
  .video-wrap {
    position: relative;
    width: 433px;
    height: 771px;
    border-radius: 10px;
    .video {
      border-radius: 28px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      video {
        border-radius: 28px;
        width: 100%;
        height: 100%;
        max-height: 95vh;
        max-width: 53vh;
        object-fit: cover;
        background: #0f0f11;
      }
    }
    .blur {
      opacity: 0.2;
      position: absolute;
      top: 200px;
      left: -80px;
      width: 406px;
      height: 548px;
      border-radius: 100%;
      filter: blur(40px);
      background: linear-gradient(164.22deg, #5398ff 11.02%, #ff69d5 95.21%);
      z-index: -1;
    }
  }

  &::v-deep {
    .el-radio__inner {
      border-color: #6d6d6d;
      background-color: transparent;
    }
    .el-radio__inner::after {
      width: 6px;
      height: 6px;
      background-color: #ff7337;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #6d6d6d;
    }
    .el-radio__label {
      color: #fff;
    }
  }
}

button {
  text-decoration: none;
  position: relative;
  border: none;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  color: #fff;
  width: 190px;
  height: 190px;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 300%;
  border-radius: 100%;
  z-index: 1;
}

button:hover {
  animation: ani 8s linear infinite;
  border: none;
}
@keyframes ani {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

button::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 100%;
}
button:hover::before {
  animation: aa 20s linear 1;
}
@keyframes aa {
  0% {
    filter: blur(2px);
  }
  5% {
    filter: blur(20px);
  }
  100% {
    filter: blur(30px);
  }
}

button:active {
  background: linear-gradient(32deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
}
</style>
